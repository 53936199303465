/**
 * The configuration object for Firebase
 */
export const firebaseConfig = {
    apiKey: "AIzaSyBPnUP-oDkQwBijjAHm2UEwgmRFtlKM_Es",
    authDomain: "stupque.firebaseapp.com",
    databaseURL: "https://stupque-default-rtdb.firebaseio.com",
    projectId: "stupque",
    storageBucket: "stupque.appspot.com",
    messagingSenderId: "405841871692",
    appId: "1:405841871692:web:6c1cec90269eca123cc5e9",
    measurementId: "G-S1VZDGZV3C"
};